import { OrganizationJsonLd } from 'next-seo';

import METADATA from 'src/constants/metadata';

const OrganizationJsonLdC = () => {
	return (
		<OrganizationJsonLd
			type="Organization"
			name="KlikDokter - Konsultasi Medis, Booking RS dan Pesan Obat"
			logo={METADATA.WEB_URL + '/images/klikdokter.svg'}
			url={METADATA.WEB_URL as string}
		/>
	);
};

export default OrganizationJsonLdC;
